.cdk-overlay-container .mat-dialog-container {
  padding: 0;
  overflow: auto;
  .mat-datepicker-content {
    display: inline;
  }
}

.no-tap-highlight {
  -webkit-tap-highlight-color: rgba(black, 0);
}

.default-popup {
  max-width: 100%;
  height: 100%;
  width: 40em;
  padding: 0;

  .title {
    display: flex;
    justify-content: center;
    border-bottom: 4px solid $color-red;
    padding: 0.7rem 2rem;

    & > span {
      font-family: Arial, sans-serif;
      font-weight: 900;
      color: $color-grey-dark;
      font-size: 1.2em;
      line-height: 2em;
      white-space: nowrap;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .content {
    padding: 1.5em 2em;
    .info {
      margin-bottom: 1.5em;
    }
    .form-group {
      margin-bottom: 1em;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      button {
        font-size: 1em;
        &[type="submit"] {
          margin-left: 2em;
          color: $color-white;
        }
      }
    }
  }
  .mat-dialog-content {
    padding: 0 2em;
    margin: 0em;
    max-height: 75vh;
    .content {
      padding: 1.5em 0;
    }
  }
}

@media screen and (max-width: $breakpoint-small),
  screen and (max-height: $breakpoint-tiny) {
  .default-popup {
    .title {
      padding: 0.5rem 1rem;
    }
    .mat-dialog-content {
      padding: 0 1rem;
      max-height: 80vh;
      .content {
        padding: 1em 0;
      }
    }
  }
}

@media (max-width: $breakpoint-small), #{$breakpoint-small-landscape} {
  .mobile-full-screen-view {
    max-width: 100vw !important;
    width: 100vw;
    height: 100%;
    .default-popup {
      display: flex;
      flex-direction: column;
      & > form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .title {
        flex: 0 0 auto;
      }
      .mat-dialog-content {
        flex: 1 1 auto;
        max-height: none;
      }
    }
  }
}

@media #{$breakpoint-small-landscape} {
  .default-popup {
    width: auto;
    .mat-dialog-content {
      max-height: 85vh;
    }
  }
}

@media (max-width: $breakpoint-tiny) {
  .default-popup .content .button-wrapper {
    justify-content: center;
    button[type="submit"] {
      margin-left: 0;
    }
  }
}
