mat-progress-bar.form-progress-bar {
  position: absolute;
  left: 0;
  z-index: 2;
  &[top] {
    top: -5px;
  }
  &:not([top]) {
    bottom: -5px;
  }
}

.form-progress-curtain {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  &:not([transparent]):after {
    background: #fafafa;
  }
}

.form-progress-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: space-around;
  mat-progress-spinner {
    z-index: 2;
  }
}
