@import "components/table-extensions";
.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $color-white;
  overflow: hidden;

  .empty-result {
    padding: 0.7em 2.5em;
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    virtual-scroll {
      overflow-y: scroll !important;
      outline: none;
      height: 100%;
      width: 100%;
    }
    @include loading-wrapper();
  }

  .row,
  .rowHeader {
    display: flex;
    .cell {
      &.minimum,
      &.medium,
      &.normal {
        flex-grow: 4;
      }
    }
    .cell:not(.action) {
      flex: 1 0 0px;
      width: 100%;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-width: 0;
      line-height: 1.5;
      & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 0;
      }
      & > span:not(.cell-mobile) {
        display: block;
      }
      &.small {
        padding-right: 1.2em;
        flex: 0;
      }
    }
    .action span {
      width: 24px;
    }
  }

  .row {
    margin-bottom: 0.7em;
    padding: 0.7em 2em;
    cursor: pointer;
    outline: none;
    align-items: center;
    .cell {
      color: #000;
      padding: 0 0.5em;
      span {
        font-size: 1em;
      }
      &.grey {
        color: $color-grey;
      }
      &.sortActive {
        span {
          font-weight: 600;
        }
      }
    }
  }

  .rowHeader {
    border-bottom: 1px solid $color-table-border;
    margin: 1.5em 1em 0.5em 1em;
    padding: 0 calc(1em + 17px) 0.5rem 1rem;

    .cell {
      display: flex;
      align-items: center;
      padding: 0 0.5em;
      span {
        font-size: 0.9em;
      }
      &.sortable {
        cursor: pointer;
        &:hover {
          color: $color-grey-dark;
        }
        mat-icon {
          display: none;
        }
        &.sortActive {
          span {
            font-weight: 600;
          }
          mat-icon {
            display: block;
          }
        }
      }
      color: $color-grey;
    }
  }

  .data-row {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
  }

  .cell-mobile {
    display: none !important;
  }
}

.table-footer {
  padding: 1em 1em 0.5em 1em;
  font-size: 0.8em;
  color: $color-grey;
  background-color: $color-white;
}

@media (max-width: $breakpoint-medium), (-webkit-min-device-pixel-ratio: 2) {
  .table {
    .rowHeader {
      padding: 0 1em 0.5rem 1rem;
    }
  }
}

@media (max-width: $breakpoint-small),
  #{$breakpoint-small-landscape} {
  .table {
    .cell-mobile,
    span {
      display: inline !important;
    }
    .empty-result {
      padding: 0 1em;
    }
    .data-row {
      flex-direction: column;
      align-items: flex-start;
      .cell {
        flex: 1 0 auto !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 0;
        &.action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        &.hide-mobile {
          display: none;
        }
      }
    }
    .rowHeader {
      display: none;
    }
    .row {
      margin-bottom: 0;
      padding: 0.7em 1em;
      .cell.grey {
        span {
          font-size: 0.9em;
        }
      }
    }
  }
}
