.blue-box {
  padding: 1.5em 1.5em 1.5em 4em;
  background: $color-blue-light;
  margin-bottom: 1.5em;
  position: relative;
  mat-icon {
    color: $color-blue;
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
  }
  p {
    margin-bottom: 1.5em;
  }
  .caption {
    margin-top: 0;
    font-weight: 600;
    color: $color-blue;
    text-transform: uppercase;
  }
}

@media screen and (max-width: $breakpoint-small) {
  .blue-box {
    margin: 0;
    padding: 1.5em 1em 1.5em 3.5em;
    border-bottom: 1px solid $color-grey-lighter;
    mat-icon {
      left: 1rem;
    }
  }
}
