@mixin table-header($left-margin: 0) {
  padding: 1em;
  display: flex;
  align-items: center;
  h1 {
    flex: 1 1 auto;
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: $left-margin;
    line-height: 1.5;
  }
}

@mixin table-header-with-button($button-width) {
  @include table-header($button-width);
  button {
    width: $button-width;
    display: flex;
    flex: 0 0 auto;
    text-align: center;
    justify-content: center;
  }
}

@mixin loading-wrapper() {
  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
