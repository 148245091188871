$color-white: #fff;
$color-red: #e30613;
$color-green: #1e9900;
$color-blue: #004899;
$color-blue-light: #e3effd;
$color-grey-dark: #3a3a3a;
$color-smoke-grey: #585858;
$color-shadow-grey: #707070;
$color-grey: #bbbbbb;
$color-grey-light: #d9d9d9;
$color-grey-lighter: #f6f6f6;
$color-grey-davy: #555;

$color-text-header: #585858;
$color-table-border: rgba(#707070, 0.15);
$color-snackbar: #999;

$font: '"Open Sans", sans-serif';

$breakpoint-tiny: 425px;
$breakpoint-small: 640px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1200px;

$breakpoint-small-landscape: "(orientation: landscape) and (max-width: 860px) and (max-height: 480px)";
